<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"> {{ $t('li_step.seed_dealer_renewal_appointment_proposed_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(loadData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="4" md="4" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.is_org_admin === 1">
                        <ValidationProvider name="Zone Name" vid="zone_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="zone_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('li_step.zone') }}
                                </template>
                                <b-form-select
                                  plain
                                    v-model="search.zone_id"
                                    :options="regionNameList"
                                    id="zone_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="Division Name" vid="division_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="division_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('org_pro_division.division') }}
                                </template>
                                <b-form-select
                                  plain
                                    v-model="search.division_id"
                                    :options="divisionList"
                                    id="division_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="District Name" vid="district_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('org_pro_district.district') }}
                                </template>
                                <b-form-select
                                  plain
                                    v-model="search.district_id"
                                    :options="districtNamesList"
                                    id="district_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="Upazila Name" vid="upazilla_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="upazilla_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('org_pro_upazilla.upazilla') }}
                                </template>
                                <b-form-select
                                  plain
                                    v-model="search.upazilla_id"
                                    :options="upazillaNamesList"
                                    id="upazilla_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="From Date" vid="from_date" rules="">
                          <b-form-group
                            :label="$t('globalTrans.from_date')"
                            label-for="from_date"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                            {{ $t('globalTrans.from_date') }}
                            </template>
                            <b-form-input
                              class="datepicker"
                              v-model="search.from_date"
                              placeholder="yyyy-mm-dd"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                          <ValidationProvider name="To Date" vid="to_date" rules="">
                          <b-form-group
                            :label="$t('globalTrans.to_date')"
                            label-for="to_date"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                            {{ $t('globalTrans.to_date') }}
                            </template>
                            <b-form-input
                              class="datepicker"
                              v-model="search.to_date"
                              placeholder="yyyy-mm-dd"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="Application Type">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="application_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('boro_import.type') }}
                            </template>
                            <b-form-select
                              plain
                              v-model="search.application_type"
                              :options="applicationTypeList"
                              id="application_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="Mobile">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="mobile"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('li_step.mobile_no') }}
                            </template>
                            <b-form-input
                              type="text"
                              id="mobile"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              v-model="search.mobile"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="application_id">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="application_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('li_step.application_id') }}
                            </template>
                            <b-form-input
                              type="text"
                              id="application_id"
                              v-model="search.application_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="license_no">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="license_no"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('li_step.license_no') }}
                            </template>
                            <b-form-input
                              type="text"
                              id="license_no"
                              v-model="search.license_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="institution_name">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="institution_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('li_step.institution_name') }}
                            </template>
                            <b-form-input
                              type="text"
                              id="institution_name"
                              v-model="search.institution_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row v-if="showData">
          <b-col md="12">
            <b-overlay :show="loading">
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('li_step.seed_dealer_renewal_appointment_proposed_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                          <b-button variant="warning">
                            <export-excel
                              worksheet="Report Sheet"
                              :title=excelHeaderValue
                              :fields=excelFields
                              :data=excelData
                              :before-generate = "headerDataExcel"
                              name="seed-dealer-renewal-appointment-report.xls">
                              {{ $t('globalTrans.export_excel') }}
                            </export-excel>
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :baseUrl="licenseRegistrationServiceBaseUrl" :url="'/configuration/report-heading/detail'" :org-id="search.org_id">
                                        {{ $t('li_step.seed_dealer_renewal_appointment_proposed_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-row class="mb-3">
                                        <b-col md="4" class="text-left">
                                          {{ $t('li_step.zone') }}: <strong>{{ search.zone_id ? getZoneName(search.zone_id) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="4" class="text-left">
                                          {{ $t('globalTrans.division') }}: <strong>{{ search.division_id ? getDivisionName(search.division_id) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="4" class="text-left">
                                          {{ $t('globalTrans.district') }}: <strong>{{ search.district_id ? getDistrictName(search.district_id) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                      </b-row>
                                      <b-row class="mb-3">
                                        <b-col md="4" class="text-left">
                                          {{ $t('org_pro_upazilla.upazilla') }}: <strong>{{ search.upazilla_id ? getUpazillaName(search.upazilla_id) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="4" class="text-left">
                                          {{ $t('globalTrans.from_date') }} : <strong>{{ search.from_date | dateFormat }}</strong>
                                        </b-col>
                                        <b-col md="4" class="text-left">
                                          {{ $t('globalTrans.to_date') }} : <strong>{{ search.to_date | dateFormat }}</strong>
                                        </b-col>
                                      </b-row>
                                      <b-row class="mb-3">
                                        <b-col md="4" class="text-left">
                                          {{ $t('boro_import.type') }}: <strong>{{ search.application_type ? getApplicationTypeName(search.application_type) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="4" class="text-left">
                                          {{ $t('li_step.mobile_no') }}: <strong>{{ search.mobile ? search.mobile : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="4" class="text-left">
                                          {{ $t('li_step.application_id') }}: <strong>{{ search.application_id ? search.application_id : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                      </b-row>
                                      <b-row class="mb-3">
                                        <b-col md="4" class="text-left">
                                          {{ $t('li_step.license_no') }}: <strong>{{ search.license_no ? search.license_no : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="4" class="text-left">
                                          {{ $t('li_step.institution_name') }}: <strong>{{ search.institution_name ? search.institution_name : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <template v-if="listData.length">
                                        <!-- <div v-for="(serviceItem, serviceIndex) in listData" :key="'ser-' + serviceIndex" class="service-area" > -->
                                          <b-table-simple hover small responsive bordered>
                                            <b-thead>
                                              <b-tr>
                                                <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                                <b-th>{{ $t('globalTrans.division') }}</b-th>
                                                <b-th>{{ $t('li_step.zone') }}</b-th>
                                                <b-th>{{ $t('globalTrans.district') }}</b-th>
                                                <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                                                <b-th>{{ $t('li_step.application_id') }}</b-th>
                                                <b-th>{{ $t('boro_import.type') }}</b-th>
                                                <b-th>{{ $t('li_step.license_no') }}</b-th>
                                                <b-th>{{ $t('li_step.ownership_name') }}</b-th>
                                                <b-th>{{ $t('li_step.institution_name') }}</b-th>
                                                <b-th>{{ $t('globalTrans.address') }}</b-th>
                                                <b-th>{{ $t('li_step.mobile_no') }}</b-th>
                                              </b-tr>
                                            </b-thead>
                                            <b-tbody>
                                              <b-tr v-for="(appItem, index) in listData" :key="index">
                                                <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                <b-td class="text-center">{{ getDivisionName(appItem.division_2278) }}</b-td>
                                                <b-td class="text-center">{{ getZoneName(appItem.office_id) }}</b-td>
                                                <b-td class="text-center">{{ getDistrictName(appItem.district_8836) }}</b-td>
                                                <b-td class="text-center">{{ getUpazillaName(appItem.upazilla_7681) }}</b-td>
                                                <b-td class="text-center">{{ $n(appItem.application_id, { useGrouping: false }) }}</b-td>
                                                <b-td class="text-center">{{ getTypeName(appItem.type) }}</b-td>
                                                <b-td class="text-center">{{ appItem.generate_id }}</b-td>
                                                <b-td class="text-center">{{ currentLocale === 'en' ? appItem.owner_name_4139 : appItem.owner_name_9446 }}</b-td>
                                                <b-td class="text-center">{{ currentLocale === 'en' ? appItem.company_na_5095 : appItem.company_na_2354 }}</b-td>
                                                <b-td class="text-center">{{ currentLocale === 'en' ? appItem.center_add_8818 : appItem.center_add_6838 }}</b-td>
                                                <b-td class="text-center">{{ appItem.phonemobil_5381 }}</b-td>
                                              </b-tr>
                                            </b-tbody>
                                          </b-table-simple>
                                        <!-- </div> -->
                                      </template>
                                      <h4 v-else class="text-center">{{ $t('globalTrans.noDataFound') }}</h4>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { seedDealerRenewalReport } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadLrcpnOn.vue'
// import ExportPdf from './export_pdf'
import { helpers } from '@/mixins/helper-functions.js'
import flatpickr from 'flatpickr'
/** Excel */
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  props: [],
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  data () {
    return {
      search: {
        org_id: 3,
        division_id: 0,
        district_id: 0,
        zone_id: 0,
        upazilla_id: 0,
        service_id: 19,
        mobile: '',
        application_id: '',
        license_no: '',
        institution_name: '',
        from_date: '',
        to_date: '',
        application_type: 0,
        paid_type: 0
      },
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      listData: [],
      districtNamesList: [],
      upazillaNamesList: [],
      excelColumnBn: {},
      excelColumn: {},
      showData: false,
      headerExcelDefault: {
        orgName: null,
        orgNameBn: null,
        address: null,
        address_bn: null
      }
    }
  },
  computed: {
    excelHeaderValue: function () {
      const headerVal = []
      if (this.$i18n.locale === 'en') {
        headerVal[0] = "Government of the People's Republic of Bangladesh"
        headerVal[1] = this.headerExcelDefault.orgName
        headerVal[2] = this.headerExcelDefault.address
        headerVal[3] = ''
        headerVal[4] = 'Seed Dealer Renewal/Recruitment Report'
        headerVal[5] = ''
        headerVal[6] = ''
        headerVal[7] = ''
        headerVal[8] = ''
        headerVal[9] = ''
        headerVal[10] = ''
      } else {
        headerVal[0] = 'গণপ্রজাতন্ত্রী বাংলাদেশ সরকার'
        headerVal[1] = this.headerExcelDefault.orgNameBn
        headerVal[2] = this.headerExcelDefault.address_bn
        headerVal[3] = ''
        headerVal[4] = 'বীজ ডিলার নবায়ন/নিয়োগ সংক্রান্ত রিপোর্ট'
        headerVal[5] = ''
        headerVal[6] = ''
        headerVal[7] = ''
        headerVal[8] = ''
        headerVal[9] = ''
        headerVal[10] = ''
      }

      const zone = this.$t('li_step.zone') + ' : ' + (this.search.zone_id ? this.getZoneName(this.search.zone_id) : this.$t('globalTrans.all')) + ';   '
      const division = this.$t('globalTrans.division') + ' : ' + (this.search.division_id ? this.getDivisionName(this.search.division_id) : this.$t('globalTrans.all')) + ';   '
      const district = this.$t('globalTrans.district') + ' : ' + (this.search.district_id ? this.getDistrictName(this.search.district_id) : this.$t('globalTrans.all'))
      const upazilla = this.$t('globalTrans.district') + ' : ' + (this.search.upazilla_id ? this.getUpazillaName(this.search.upazilla_id) : this.$t('globalTrans.all')) + ';   '
      const fromDate = this.$t('globalTrans.from_date') + ' : ' + (this.search.from_date ? this.search.from_date : '') + ';   '
      const toDate = this.$t('globalTrans.to_date') + ' : ' + (this.search.to_date ? this.search.to_date : '')
      const type = this.$t('boro_import.type') + ' : ' + (this.search.application_type ? this.getApplicationTypeName(this.search.application_type) : this.$t('globalTrans.all')) + ';   '
      const mobile = this.$t('li_step.mobile_no') + ' : ' + (this.search.mobile ? this.search.mobile : this.$t('globalTrans.all')) + ';   '
      const applicationId = this.$t('li_step.application_id') + ' : ' + (this.search.application_id ? this.search.application_id : this.$t('globalTrans.all'))
      const licenseNo = this.$t('li_step.license_no') + ' : ' + (this.search.license_no ? this.search.license_no : this.$t('globalTrans.all')) + ';   '
      const institutionName = this.$t('li_step.institution_name') + ' : ' + (this.search.institution_name ? this.search.institution_name : this.$t('globalTrans.all'))
      headerVal[6] = zone + division + district
      headerVal[7] = upazilla + fromDate + toDate
      headerVal[8] = type + mobile + applicationId
      headerVal[9] = licenseNo + institutionName
      return headerVal
    },
    excelFields: function () {
      if (this.$i18n.locale === 'bn') {
        return {
          'ক্রমিক সংখ্যা': 'serial',
          'বিভাগ ': 'division',
          'অঞ্চল ': 'zone',
          'জেলা ': 'district',
          'উপজেলা ': 'upazila',
          'আবেদন আইডি': 'application_id',
          'ধরণ ': 'type',
          'লাইসেন্স নং': 'license_no',
          'সত্ত্বাধিকার নাম': 'ownership_name',
          'প্রতিষ্ঠানের নাম': 'institution_name',
          'ঠিকানা ': 'address',
          'মোবাইল নম্বর': 'mobile_no'
        }
      } else {
        return {
          'SL No': 'serial',
          'Division ': 'division',
          'Zone ': 'zone',
          'District ': 'district',
          'Upazila ': 'upazila',
          'Application Id': 'application_id',
          'Type ': 'type',
          'License No': 'license_no',
          'Ownership Name': 'ownership_name',
          'Institution Name': 'institution_name',
          'Address ': 'address',
          'Mobile No': 'mobile_no'
        }
      }
    },
    excelData: function () {
      const excelData = []
      this.listData.forEach((appItem, index) => {
        excelData.push({
          serial: this.$n(index + 1),
          division: this.getDivisionName(appItem.division_2278),
          zone: this.getZoneName(appItem.office_id),
          district: this.getDistrictName(appItem.district_8836),
          upazila: this.getUpazillaName(appItem.upazilla_7681),
          application_id: this.$n(appItem.application_id, { useGrouping: false }),
          type: this.getTypeName(appItem.type),
          license_no: appItem.generate_id,
          ownership_name: this.currentLocale === 'en' ? appItem.owner_name_4139 : appItem.owner_name_9446,
          institution_name: this.currentLocale === 'en' ? appItem.company_na_5095 : appItem.company_na_2354,
          address: this.currentLocale === 'en' ? appItem.center_add_8818 : appItem.center_add_6838,
          mobile_no: "'" + appItem.phonemobil_5381 + "'"
        })
      })
      return excelData
    },
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
        return this.$store.state.commonObj.loading
    },
    divisionList () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    orgList () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    serviceNamesList () {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => (item.org_id === 3))
    },
    yearList () {
      return helpers.getYearListCurrent({ yearFrom: 2021 })
    },
    regionNameList () {
      const tmpList = this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 132)
      return tmpList.map(item => ({ text: item.text, value: item.value, text_en: item.text_en, text_bn: item.text_bn }))
    },
    monthList () {
      return this.$store.state.commonObj.monthList
    },
    applicationTypeList () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'New' : 'নতুন', text_en: 'New', text_bn: 'নতুন' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Reneiw' : 'নবায়ন', text_en: 'Reneiw', text_bn: 'নবায়ন' }
      ]
      return list
    },
    paymentTypeList () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'অনলাইন' : 'Online',
          text_en: 'Online',
          text_bn: 'অনলাইন'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অফলাইন' : 'Offline',
          text_en: 'Offline',
          text_bn: 'অফলাইন'
        }
      ]
    },
    authOrgId () {
      const authUser = this.$store.state.Auth.authUser
      const activeRoleId = this.$store.state.Auth.activeRoleId
      return activeRoleId !== 1 ? authUser.org_id : 0
    }
  },
  watch: {
    'search.org_id': function (newVal) {
      if (newVal) {
        this.serviceNamesList = this.getServiceList(newVal)
      } else {
        this.serviceNamesList = []
      }
    },
    'search.division_id': function (newVal) {
      if (newVal) {
        this.districtNamesList = this.getDistrictList(newVal)
      } else {
        this.districtNamesList = []
      }
    },
    'search.district_id': function (newVal) {
      if (newVal) {
        this.upazillaNamesList = this.getUpazillaList(newVal)
      } else {
        this.upazillaNamesList = []
      }
    }
  },
  created () {
    if (this.authOrgId) {
      this.search.org_id = this.authOrgId
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      if (this.$store.state.Auth.authUser.is_org_admin !== 1) {
        this.search.zone_id = this.$store.state.Auth.authUser.office_id
      }
    }
  },
  mounted () {
    core.index()
    flatpickr('.datepicker', {})
    this.headerDataExcel()
  },
  methods: {
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: this.search.org_id }, this.search)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, seedDealerRenewalReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    headerDataExcel () {
      RestApi.getData(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail/3').then(response => {
        if (response.success) {
          const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 3)
          const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
          const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
          this.headerExcelDefault.orgName = orgName
          this.headerExcelDefault.orgNameBn = orgNameBn
          this.headerExcelDefault.address = response.data.address
          this.headerExcelDefault.address_bn = response.data.address_bn
        }
      })
    },
    getRegionNameList (serviceId) {
      let officeType = 0
      if (serviceId === 11) {
        officeType = 72
      } else if (serviceId === 19) {
        officeType = 132
      }
      const tmpList = this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === officeType)
      return tmpList.map(item => ({ text: item.text, value: item.value, text_en: item.text_en, text_bn: item.text_bn }))
    },
    // pdfExport () {
    //   const reportTitle = this.$t('li_step.seed_dealer_renewal_appointment_proposed_report')
    //   ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', this.search.org_id, reportTitle, this)
    // },
    async loadData () {
      this.showData = true
      this.listData = []
      this.$store.commit('mutateCommonProperties', {
          loading: true
      })
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, seedDealerRenewalReport, this.search)
      if (result.success) {
        this.$store.commit('mutateCommonProperties', {
          loading: false
        })
        this.listData = result.data
      } else {
        this.listData = []
        this.$store.commit('mutateCommonProperties', {
          loading: false
        })
      }
    },
    getDistrictList (divId) {
      const tmpDistrict = this.$store.state.commonObj.districtList.filter(item => (item.division_id === divId))
      return tmpDistrict.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en })
      })
    },
    getUpazillaList (disId) {
      const tmpUpazila = this.$store.state.commonObj.upazilaList.filter(item => (item.district_id === disId))
      return tmpUpazila.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en })
      })
    },
    getServiceList (orgId) {
      const tmpService = this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => (item.org_id === orgId))
      return tmpService.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en })
      })
    },
    getDivisionName (id) {
      const obj = this.$store.state.commonObj.divisionList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getDistrictName (id) {
      const obj = this.$store.state.commonObj.districtList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getUpazillaName (id) {
      const obj = this.$store.state.commonObj.upazilaList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getTypeName (id) {
      const obj = this.applicationTypeList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getZoneName (id) {
      const obj = this.$store.state.commonObj.officeList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getServiceName (id) {
      const obj = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getMonthName (id) {
      const obj = this.$store.state.commonObj.monthList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getApplicationTypeName (id) {
      const obj = this.applicationTypeList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getPaymentTypeName (id) {
      const obj = this.paymentTypeList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    }
  }
}
</script>
<style >
.table-bordered th{
border: 1px solid #000 !important;
}
</style>
